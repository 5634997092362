<template>
  <vue-context ref="newTabContext">
    <li>
      <a
        href="#"
        @click.prevent="$emit('open-in-new-tab', item)"
      >
        <feather-icon icon="ExternalLinkIcon" />
        Open In New Tab
      </a>
    </li>
  </vue-context>
</template>

<script>
import VueContext from 'vue-context'

export default {
  components: {
    VueContext,
  },
  data() {
    return {
      item: null,
    }
  },
  methods: {
    open(event, item = null) {
      this.item = item
      this.$refs.newTabContext.open(event)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~vue-context/src/sass/vue-context';
</style>
