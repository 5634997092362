<template>
  <div>
    <div v-if="!portfolioError && portfolios.length > 0">

      <ecommerce-statistics
        title="My Portfolios"
        explanation="A summary of my portfolios and links to view them"
        :updated="charts.portfolioSummaryUpdated"
        :data="charts.portfolioSummary"
        :loading="loading"
      />

      <b-row>
        <b-col xl="3">
          <b-row class="match-height">
            <b-col
              xl="12"
              md="12"
            >
              <ecommerce-order-chart
                explanation="Breakdown of possible and final future maturities"
                :height="160"
                :data="charts.futureMaturities"
                :loading="loading"
              />
            </b-col>
            <b-col
              xl="12"
              md="4"
            >
              <ecommerce-pie-chart
                :loading="loading"
                :data="charts.underlyings"
              />
            </b-col>
            <b-col
              xl="12"
              md="4"
            >
              <ecommerce-pie-chart
                :loading="loading"
                :data="charts.issuers"
              />
            </b-col>
            <b-col
              xl="12"
              md="4"
            >
              <ecommerce-pie-chart
                :loading="loading"
                :data="charts.productTypes"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col xl="9">
          <!-- Searchbar
          <div class="mb-2 d-flex justify-content-between">
            <b-input-group class="input-group-merge w-25">
              <b-form-input
                placeholder="Search portfolio"
                class="search-product"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-append>
            </b-input-group>
          </div> -->

          <portfolios-table
            :table-data="portfolios"
            :loading="portfoliosLoading"
            :actions="true"
            @row-clicked="portfolioClicked"
            @portfolioDeleted="portfolioDeleted"
            @create-portfolio="createPortfolioModal.show = true"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else-if="portfolios.length < 1">
      <div class="d-flex flex-column justify-content-center align-items-center py-5">
        <div class="py-3 mx-5 text-center">
          <p>You do not have any portfolios yet.</p>
          <p>You can search for products to create a portfolio from your search results. You can also create an empty portfolio and add products to it later.</p>
        </div>

        <div class="mt-1">
          <b-button
            variant="primary"
            class="mr-1"
            @click.prevent="$router.push('/products')"
          >
            Go To Products
          </b-button>
          <b-button
            variant="primary"
            @click.prevent="createPortfolioModal.show = true"
          >
            Create Portfolio
          </b-button>
        </div>
      </div>
    </div>
    <error-display
      v-else
      @action-clicked="getuser"
    />
    <b-modal
      v-model="createPortfolioModal.show"
      centered
      title="Create Empty Portfolio"
      hide-footer
    >
      <create-portfolio-modal-content
        :portfolios="portfolios"
        description="empty"
        @portfolio-created="portfolioCreated"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import CreatePortfolioModalContent from '@/views/components/CreatePortfolioModalContent.vue'
import EcommerceStatistics from './ecommerce/EcommerceStatistics.vue'
import EcommerceOrderChart from './ecommerce/EcommerceOrderChart.vue'
import EcommercePieChart from './ecommerce/EcommercePieChart.vue'
import PortfoliosTable from './components/PortfoliosTable.vue'
import ErrorDisplay from './components/ErrorDisplay.vue'

export default {
  name: 'Portfolios',
  components: {
    BRow,
    BCol,
    BButton,
    EcommerceStatistics,
    EcommerceOrderChart,
    EcommercePieChart,
    PortfoliosTable,
    CreatePortfolioModalContent,
    ErrorDisplay,
  },
  data() {
    return {
      charts: {
        portfolioSummary: [],
        futureMaturities: {
          categories: [1, 2, 3, 4],
          series: [
            {
              name: 'Maturities',
              data: [],
            },
          ],
        },
        underlyings: {
          title: 'Underlyings',
          explanation: 'Proportion of products by underlyings',
          labels: [],
          series: [],
          colors: [$themeColors.success, '#28c76f66', '#28c76f33'],
        },
        issuers: {
          title: 'Issuers',
          explanation: 'Proportion of products by issuers',
          labels: [],
          series: [],
          colors: [$themeColors.primary, '#7367f066', '#7367f033'],
        },
        productTypes: {
          title: 'Product Types',
          explanation: 'Proportion of products by types',
          labels: [],
          series: [],
          colors: [$themeColors.warning, '#ff9f4366', '#ff9f4333'],
        },
      },
      clickedPortfolioId: '',
      items: [],
      loading: false,
      createPortfolioModal: {
        show: false,
      },
      portfolioError: null,
    }
  },
  computed: {
    portfolios() {
      return this.$store.state.app.portfolios
    },
    portfoliosLoading() {
      return this.$store.state.app.portfolioLoading
    },
  },
  created() {
    this.changeall()
    this.$store.dispatch('app/updatePortfolios')
  },
  activated() {
    // Check if portfolios has changed and reload data
    if (this.$store.state.app.portfolioUpdated) {
      this.getuser()
      this.$store.commit('app/SET_PORTFOLIO_UPDATED', false)
    }
  },
  methods: {
    changeall() {
      this.getuser()
    },

    getuser() {
      this.loading = true

      // Reset error flag
      this.portfolioError = null

      this.$http
        .get('portfolio.cfc?method=getUserPortfolioInfo')
        .then(response => {
          this.charts.portfolioSummary = response.data.userproductuniverse.items
          this.charts.portfolioSummaryUpdated = response.data.userproductuniverse.updated
          this.charts.futureMaturities = response.data.portfoliosummary.futurematurities
          this.charts.underlyings.series = response.data.portfoliosummary.underlyings.series
          this.charts.underlyings.labels = response.data.portfoliosummary.underlyings.labels
          this.charts.issuers.series = response.data.portfoliosummary.issuers.series
          this.charts.issuers.labels = response.data.portfoliosummary.issuers.labels
          this.charts.productTypes.series = response.data.portfoliosummary.producttypes.series
          this.charts.productTypes.labels = response.data.portfoliosummary.producttypes.labels
          this.items = response.data.portfolioactivity
        })
        .catch(() => {
          console.log('Error Loading Portfolio Data')
          this.portfolioError = true
        })
        .then(() => { this.loading = false })
    },
    portfolioClicked(item, openInNewTab = false) {
      this.clickedPortfolioId = item.portfolioId
      if (openInNewTab) {
        const route = this.$router.resolve({ name: 'portfolio-report', params: { portfolioId: this.clickedPortfolioId } })
        window.open(route.href, '_blank')
      } else {
        this.$router.push({ name: 'portfolio-report', params: { portfolioId: this.clickedPortfolioId } })
      }
      return item
    },
    portfolioCreated() {
      this.createPortfolioModal.show = false
      this.$bvToast.toast('Portfolio Created', {
        title: 'Success',
        variant: 'success',
        solid: true,
      })
      this.getuser()

      // Update portfolios list inside the store
      this.$store.dispatch('app/updatePortfolios')

      // Reload user data
      this.changeall()
    },
    portfolioDeleted() {
      this.getuser()

      // Update portfolios list inside the store
      this.$store.dispatch('app/updatePortfolios')

      // Reload user data
      this.changeall()
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
