<template>
  <b-form
    class="py-1"
    @submit.prevent="createPortfolio"
  >
    <p class="text-muted">
      {{ message }}
    </p>

    <b-form-group>
      <label for="portfolioName">Portfolio Name</label>
      <b-form-input
        id="portfolioName"
        v-model="portfolioName"
        type="text"
        :state="validationError === null ? null : false"
        placeholder="My Portfolio"
      />
      <b-form-invalid-feedback>
        {{ validationError }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button
      variant="primary"
      class="mt-1"
      :disabled="loading || !portfolioName"
      @click.prevent="createPortfolio"
    >
      CREATE <b-spinner
        v-if="loading"
        small
      />
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm, BFormInput, BFormGroup, BButton, BSpinner, BFormInvalidFeedback,
} from 'bootstrap-vue'
import strings from '@/strings'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
  },
  props: {
    productIds: {
      type: Array,
      default: () => [],
    },
    portfolios: {
      type: Array,
      required: true,
    },
    description: {
      type: String,
      default: 'none', // Values: empty, none, product
    },
    viewPortfolio: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return ({
      portfolioName: '',
      loading: false,
      validationError: null,
    })
  },
  computed: {
    message() {
      if (this.description === 'product') {
        return 'Create a new portfolio that includes all the products in the current results. You can add additional products or remove any of them later.'
      } if (this.description === 'empty') {
        return 'Create a new portfolio that is initially empty. You will need to search for products to add later.'
      }
      return ''
    },
  },
  methods: {
    createPortfolio() {
      this.loading = true
      this.validationError = null

      // Check if the portfolio name is already used
      const nameExists = this.portfolios.find(
        portfolio => portfolio.portfolioName.toLowerCase() === this.portfolioName.toLowerCase(),
      )

      if (nameExists) {
        this.loading = false
        // Set input error message
        this.validationError = 'Portfolio Name Already Exists. Try Another One.'
        return
      }

      this.$http.post('portfolio.cfc?method=createPortfolio', new URLSearchParams({
        portfolioName: this.portfolioName,
        productIds: this.productIds,
      })).then(response => {
        this.portfolioName = ''
        this.$emit('portfolio-created', {
          portfolioId: response.data.portfolioId,
          viewPortfolio: this.viewPortfolio,
        })
      }).catch(error => {
        this.$bvToast.toast('Portfolio Creation Failed', {
          title: strings.defaultErrorTitle,
          variant: 'danger',
          solid: true,
        })
        console.log('Error creating portfolio: ', error)
      }).then(() => {
        this.loading = false
      })
    },
  },
}
</script>
