<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
      <b-card-text
        v-if="showUpdated"
        class="font-small-2 mr-25 mb-0"
      >
        {{ updated }}
      </b-card-text>
      <b-button
        v-if="createPortfolio"
        variant="primary"
        @click.prevent="$emit('create-portfolio')"
      >
        Create Portfolio
      </b-button>
    </b-card-header>
    <b-card-sub-title
      v-if="explanation"
      class="statistics-explanation"
    >
      {{ explanation }}
    </b-card-sub-title>
    <b-card-body class="statistics-body">
      <loading-container v-if="loading" />
      <no-data-container v-else-if="!data || data.length < 0" />
      <b-row v-else>
        <b-col
          v-for="item in data"
          :key="item.icon"
          :class="`${item.customClass} d-flex`"
        >
          <!-- <b-col
            v-for="item in data"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
          > -->
          <div
            v-if="item.subtitle === 'All products'"
            class="vertical-divider"
          />
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BButton,
} from 'bootstrap-vue'

import LoadingContainer from '@/views/components/LoadingContainer.vue'
import NoDataContainer from '@/views/components/NoDataContainer.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    LoadingContainer,
    NoDataContainer,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    updated: {
      type: String,
      default: '',
    },
    explanation: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    createPortfolio: {
      type: Boolean,
      default: false,
    },
    showUpdated: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.vertical-divider {
  width: 1px;
}

@media (min-width: 992px) {
  .vertical-divider {
    width: 1px;
    background: rgba(91,99,161,0.5);
    height: 100%;
    margin-right: 50px;
  }
}
</style>
