<template>
  <div
    class="text-center py-5 my-2 d-flex flex-column justify-content-center align-items-center"
  >
    {{ message }}
    <b-button
      v-if="action"
      variant="primary"
      class="mt-3"
      @click="$emit('action-clicked')"
    >
      {{ action }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    message: {
      type: String,
      default: 'No data available',
      required: false,
    },
    action: {
      type: String,
      default: null,
    },
  },
}
</script>
