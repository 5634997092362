<template>
  <b-card
    v-if="data"
    body-class="pb-50"
  >
    <b-card-title>
      Future Maturities
    </b-card-title>
    <b-card-sub-title
      v-if="explanation"
      class="mb-1"
    >
      {{ explanation }}
    </b-card-sub-title>
    <loading-container v-if="loading" />
    <!-- chart -->
    <vue-apex-charts
      v-else
      :height="height"
      :options="dynamicoptions"
      :series="data.series"
    />
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import LoadingContainer from '@/views/components/LoadingContainer.vue'

// const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    VueApexCharts,
    LoadingContainer,
  },
  props: {
    explanation: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 80,
    },
    data: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      statisticsOrder: {
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: false,
            toolbar: {
              show: false,
            },
            animations: {
              enabled: this.animate,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
              dataLabels: {
                position: 'top',
                hideOverflowingLabels: false,
              },
              // endingShape: 'rounded',
              // colors: {
              //   backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
              //   backgroundBarRadius: 5,
              // },
            },
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
          },
          dataLabels: {
            enabled: false,
            offsetY: -20,
          },
          colors: [$themeColors.danger, $themeColors.info],
          xaxis: {
            categories: ['2021', '2022', '2023', '2024'],
            labels: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: true,
            decimalsInFloat: 2,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
  computed: {
    dynamicoptions() {
      const options = { ...this.statisticsOrder.chartOptions }
      options.xaxis.categories = this.data.categories
      return options
    },
  },
}
</script>
