<template>
  <div>
    <b-card
      v-if="tableData"
      no-body
      class="card-company-table"
    >
      <div class="d-flex justify-content-between">
        <div class="font-weight-bold px-2 pt-2">
          {{ filterResult.length }} results found <span v-if="filterResult.length > perPage">| Showing results {{ `from ${showing[0]} to ${showing[1]}` }}</span>
        </div>
        <div
          v-if="actions"
          class="px-2 pt-2"
        >
          <b-button
            class="mr-1"
            variant="primary"
            @click.prevent="$emit('create-portfolio')"
          >
            Create Portfolio
          </b-button>
          <b-button
            variant="primary"
            to="/upload-spreadsheet"
          >
            Upload Spreadsheet
          </b-button>
        </div>
      </div>
      <b-input-group class="p-2 pt-1 input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="filter"
          placeholder="Search (ID, name or date)"
          :disabled="loading || tableData.length < 1"
        />
      </b-input-group>
      <loading-container v-if="loading" />
      <template v-else>
        <b-table
          class="mb-0"
          :fields="fields"
          :items="filterResult"
          :current-page="currentPage"
          :per-page="perPage"
          responsive
          hover
          @row-clicked="rowClicked"
          @row-contextmenu="handleContext"
        >
          <!-- portfolio -->
          <template #cell(portfolioName)="data">
            <div class="d-flex align-items-center">
              <b-avatar
                class="mr-1"
                :variant="data.item.avatarColor"
              >
                <feather-icon
                  size="18"
                  :icon="data.item.avatarIcon"
                />
              </b-avatar>
              <span>{{ data.item.portfolioName }}</span>
            </div>
          </template>

          <template #cell(created)="data">
            <span>{{ formatDate(data.item.created) }}</span>
          </template>

          <template #cell(modified)="data">
            <span>{{ formatDate(data.item.modified) }}</span>
          </template>

          <!-- performance -->
          <template #cell(performance)="data">
            <div class="d-flex align-items-center">
              <span class="font-weight-bolder mr-1">{{ data.item.performance+'%' }}</span>
              <feather-icon
                :icon="data.item.loss ? 'TrendingDownIcon':'TrendingUpIcon'"
                :class="data.item.loss ? 'text-danger':'text-success'"
              />
            </div>
          </template>

          <!-- action -->
          <template #cell(portfolioIdportfolio)="data">
            <div class="d-flex align-items-center">
              <b-button
                variant="gradient-warning"
                class="btn-icon rounded-circle"
                @click.prevent="$router.push({ name: 'portfolio-edit', params: { portfolioId: data.item.portfolioId } })"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                variant="danger"
                class="btn-icon rounded-circle ml-1"
                @click.prevent="showDeleteModal(data.item.portfolioId)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
        <div
          class="p-2 d-flex justify-content-between"
        >
          <div class="my-2">
            <span class="pb-1">Rows Per Page</span>
            <b-form-select
              v-model="perPage"
              :options="perPageOption"
              class="pagination-select"
            />
          </div>

          <div class="my-2 d-flex flex-column justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="filterResult.length"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </div>
        </div>
      </template>
    </b-card>
    <open-in-new-tab-dialog
      ref="openInNewTabContext"
      @open-in-new-tab="openInNewTab"
    />
  </div>
</template>

<script>
import {
  BCard, BTable, BAvatar, BButton, BFormSelect, BPagination, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import LoadingContainer from '@/views/components/LoadingContainer.vue'
import OpenInNewTabDialog from '@/views/components/OpenInNewTabDialog.vue'
import dayjs from 'dayjs'
import strings from '@/strings'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BButton,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    LoadingContainer,
    OpenInNewTabDialog,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'portfolioId', label: 'ID', thStyle: { width: '5%' }, sortable: true,
        },
        {
          key: 'portfolioName', label: 'Portfolio', thStyle: { width: '32%' }, sortable: true,
        },
        {
          key: 'created', label: 'Created', thStyle: { width: '17%' }, sortable: true,
        },
        {
          key: 'modified', label: 'Modified', thStyle: { width: '17%' }, sortable: true,
        },
        {
          key: 'productCount', label: '# Products', thStyle: { width: '17%' }, sortable: true,
        },
        { key: 'portfolioIdportfolio', label: 'Action', thStyle: { width: '10%' } },
        //      { key: 'performance', label: 'Performance', thStyle: { width: '10%' } },
      ],
      perPage: 20,
      currentPage: 1,
      perPageOption: [5, 10, 15, 20, 50, 100],
      filter: '',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      deleteLoading: false,
    }
  },
  computed: {
    filterResult() {
      if (this.filter && this.filter.length > 0) {
        return this.tableData.filter(item => (item.portfolioName.toLowerCase().search(this.filter.toLowerCase()) !== -1)
            || (item.portfolioId.toString().search(this.filter.toLowerCase()) !== -1)
            || (this.formatDate(item.created).search(this.filter.toLowerCase()) !== -1)
            || (this.formatDate(item.modified).search(this.filter.toLowerCase()) !== -1))
      }
      return this.tableData
    },
    showing() {
      const start = (this.perPage * this.currentPage) - this.perPage + 1
      const end = (this.perPage * this.currentPage) > this.filterResult.length ? this.filterResult.length : (this.perPage * this.currentPage)
      return [start, end]
    },
  },
  methods: {
    rowClicked(item, index, event) {
      this.$emit('row-clicked', item, event.ctrlKey)
    },
    showDeleteModal(portfolioId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this portfolio?', {
          title: 'Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deletePortfolio(portfolioId)
          }
        })
    },
    deletePortfolio(portfolioId) {
      this.loading = true

      this.$http.post('portfolio.cfc?method=deletePortfolio', new URLSearchParams({
        portfolioId,
      })).then(() => {
        this.$bvToast.toast('Portfolio Deleted', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })

        // Emit event to reload portfolios
        this.$emit('portfolioDeleted')
      }).catch(() => {
        this.$bvToast.toast(strings.defaultErrorMessage, {
          title: strings.defaultErrorTitle,
          variant: 'danger',
          solid: true,
        })
      }).then(() => {
        this.loading = false
      })
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    handleContext(item, index, event) {
      event.preventDefault()
      this.$refs.openInNewTabContext.open(event, item)
    },
    openInNewTab(item) {
      this.$emit('row-clicked', item, true)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '~vue-context/src/sass/vue-context';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
