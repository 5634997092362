<template>
  <b-card
    v-if="data"
    class="earnings-card"
    :title="data.title"
  >
    <b-card-sub-title
      v-if="data.explanation"
      class="mb-2"
    >
      {{ data.explanation }}
    </b-card-sub-title>
    <!-- chart -->
    <loading-container v-if="loading" />
    <vue-apex-charts
      v-else
      ref="chart"
      height="160"
      :options="dynamicoptions"
      :series="data.series"
    />
  </b-card>
</template>

<script>
import { BCard, BCardSubTitle } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import LoadingContainer from '@/views/components/LoadingContainer.vue'

export default {
  components: {
    BCard,
    BCardSubTitle,
    VueApexCharts,
    LoadingContainer,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const totalLabel = this.data.labels.length ? this.data.labels[0] : ''
    const totalValue = this.data.series.length ? `${this.data.series[0]}%` : ''
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
            events: {
              animationEnd(chartContext) {
                chartContext.toggleDataPointSelection(0)
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: this.data.labels,
          stroke: { width: 0 },
          colors: this.data.colors,
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              // startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: totalLabel,
                    formatter() {
                      return totalValue
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
  },
  computed: {
    dynamicoptions() {
      const options = { ...this.earningsChart.chartOptions }
      options.labels = this.data.labels
      const totalLabel = this.data.labels.length ? this.data.labels[0] : ''
      const totalValue = this.data.series.length ? `${this.data.series[0]}%` : ''
      options.plotOptions.pie.donut.labels.total = {
        show: true,
        offsetY: 15,
        label: totalLabel,
        formatter() {
          return totalValue
        },
      }
      return options
    },
  },
}
</script>
